import {Inject, Injectable} from "@angular/core";
import {ApiCommunicationService} from "../../model/services/ApiCommunicationService";

enum ExportType {
	EXCEL,
	PDF
}

@Injectable()
export class ExportFilesService {

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {}

	public downloadTemplate(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.api.portfolio().getTemplate().subscribe(resp => {
				this.downloadItem(resp, ExportType.EXCEL, "template.xlsx");
				return resolve(true);
			});
		});
	}

	public downloadPDF(id: string): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.api.auditLog().createPdf(id).subscribe(resp => {
				this.downloadItem(resp, ExportType.PDF, "results.pdf");
				return resolve(true);
			}, error => reject(error));
		});
	}

	public downloadExcel(id: string): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.api.auditLog().getExcel(id).subscribe(resp => {
				this.downloadItem(resp, ExportType.EXCEL, `exposures-${id}.xlsx`);
				return resolve(true);
			}, error => reject(error));
		});
	}

	public downloadFundListTemplate(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.api.fund().downloadFundListTemplate().subscribe(resp => {
				this.downloadItem(resp, ExportType.EXCEL, "fund-list-template.xlsx");
				resolve(true);
			});
		});
	}

	public downloadExemption(id: string): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.api.exemption().getExemption(id).subscribe(resp => {
				this.downloadItem(resp, ExportType.EXCEL, `exemption-list-${id}.xlsx`);
				resolve(true);
			});
		});
	}

	public downloadExemptionTemplate(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.api.exemption().getExemptionTemplate().subscribe(resp => {
				this.downloadItem(resp, ExportType.EXCEL, "exemption-template.xlsx");
				resolve(true);
			});
		});
	}


	private downloadItem(data: Blob, type: ExportType, filename: string) {
		// create blob
		let newBlob;

		// set application type
		switch (type) {
			case ExportType.EXCEL:
				newBlob = new Blob([data], {type: "application/vnd.ms-excel"});
				break;
			case ExportType.PDF:
				newBlob = new Blob([data], { type: "application/pdf" });
				break;
		}

		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob, filename);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const downloadableData = window.URL.createObjectURL(newBlob);

		const link = document.createElement("a");
		link.href = downloadableData;
		link.download = filename;
		// this is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));

		setTimeout(function () {
			// For Firefox it is necessary to delay revoking the ObjectURL
			window.URL.revokeObjectURL(downloadableData);
			link.remove();
		}, 100);
	}
}
