export enum AdminRoute {
	ADMIN = "admin",
	FUNDS = "funds",
	GROUPS = "groups",
	USERS = "users",
	AUDIT_LOG = "audit-log",
	FILES = "files",
	RESULTS = "results",
	CALCULATION_RULES = "calculation-rules",
	COUNTRY_SETTINGS = "settings"
}
