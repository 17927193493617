export enum StepEngineRoute {
	STEP_ENGINE = "step-engine",
	TRANSACTION_SELECTION = "transaction-selection",
	CONTRIBUTION = "contribution/:id",
	DISTRIBUTION = "distribution/:id",
	SWITCH = "switch/:id",
	MERGER = "merger/:id",
	JURISDICTION_SELECTION = "jurisdiction-selection",
	DATA_CONFIRMATION = "data-confirmation",
	QUESTIONS = "questions",
	SUMMARY = "summary",
	AUTH_ERROR = "error"
}
