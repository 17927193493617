import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {User} from "../user/User";
import {PagedUserRequest} from "../../request/user/PagedUserRequest";
import {PagedResponse} from "../../response/PagedResponse";
import {UserRequest} from "../../request/user/UserRequest";

export class UserApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/user";

	/**
	 * Returns the logged in user
	 */
	public getLoggedInUser(): Observable<User> {
		return this.apiClient.get(this.apiRoute);
	}

	/**
	 * Returns group user list
	 * @param {PagedUserRequest} request
	 */
	public getUserList(request: PagedUserRequest): Observable<PagedResponse<User>> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/list`, request);
	}

	public postUser(payload: UserRequest): Observable<User> {
		return this.apiClient.authorizedOnly().post(this.apiRoute, payload);
	}

	/**
	 * Updates user data
	 * @param {string} id
	 * @param {User} payload
	 * @returns {Observable<User>} updated User
	 */
	public updateUser(id: string, payload: UserRequest): Observable<User> {
		return this.apiClient.authorizedOnly().put(`${this.apiRoute}/${id}`, payload);
	}

	public getUnassignedUserList(query: string): Observable<Array<User>> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/new/list`, query ? {name: query} : null);
	}

	public deleteUser(id: string) {
		return this.apiClient.authorizedOnly().delete(`${this.apiRoute}/${id}`, { responseType: "text" });
	}
}
