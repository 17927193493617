import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {PagedResponse} from "../../response/PagedResponse";
import {Fund} from "../fund/Fund";
import {PagedFundRequest} from "../../request/fund/PagedFundRequest";
import {NewFundRequest} from "../../request/fund/NewFundRequest";
import {tap} from "rxjs/operators";
import {UpdateFundRequest} from "../../request/fund/UpdateFundRequest";

export class FundApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/fund";

	/**
	 * Returns a list of funds
	 * @param {PagedFundRequest} pagedFundRequest
	 */
	public getFunds(pagedFundRequest: PagedFundRequest): Observable<PagedResponse<Fund>> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/list`, pagedFundRequest).pipe(tap((response: PagedResponse<Fund>) => {

			// transform domicile code to actual domicile data and add state and default state value to umbrella funds
			return response.embedded.forEach(fund => {
				Fund.transform(fund);
			});

		}));
	}

	/**
	 * Create a new fund
	 * @param {NewFundRequest} payload
	 */
	public postFund(payload: NewFundRequest): Observable<Fund> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}`, payload);
	}

	/**
	 * Delete a fund
	 * @param {string} id
	 */
	public deleteFund(id: string): Observable<string> {
		return this.apiClient.authorizedOnly().delete(`${this.apiRoute}/${id}`, { responseType: "text" });
	}

	/**
	 * Update a fund
	 * @param {string} id
	 * @param {UpdateFundRequest} payload
	 */
	public updateFund(id: string, payload: UpdateFundRequest): Observable<Fund> {
		return this.apiClient.authorizedOnly().put(`${this.apiRoute}/${id}`, payload);
	}

	public downloadFundListTemplate(): Observable<Blob> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/template.xlsx`, null, {responseType: "blob"});
	}
}
