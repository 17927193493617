import {AbstractApiConnector} from "./AbstractApiConnector";
import {PagedAuditLogRequest} from "../../request/audit-log/PagedAuditLogRequest";
import {Observable} from "rxjs";
import {PagedResponse} from "../../response/PagedResponse";
import {AuditLog} from "../audit-log/AuditLog";
import {CalculationResponse} from "../../response/portfolio/CalculationResponse";
import {ChargeabilityResponse} from "../../response/portfolio/ChargeabilityResponse";

export class AuditLogApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/auditLog";

	public fetchAuditLogs(params: PagedAuditLogRequest): Observable<PagedResponse<AuditLog>> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/list`, params);
	}

	public fetchCalculation(id: string): Observable<CalculationResponse> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${id}/calculate`);
	}

	public fetchChargeability(id: string, jurisdiction: string): Observable<ChargeabilityResponse> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${id}/chargeability`, jurisdiction ? { jurisdiction } : null);
	}

	public createPdf(id: string): Observable<Blob> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${id}/pdf`, null, {responseType: "blob"});
	}

	public getExcel(id: string): Observable<Blob> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${id}/xlsx`, null, {responseType: "blob"});
	}

}
