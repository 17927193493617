import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

export interface AngularConfig {
	host: string;
	storage_prefix: string;
	login_prefix: string;
	key: string;
}

@Injectable()
export class ConfigProviderService {

	private _config: AngularConfig = null;

	constructor(private http: HttpClient) {

	}

	public getConfiguration(): AngularConfig {
		return this._config;
	}

	load() {
		return new Promise((resolve, reject) => {
			this.http.get("assets/config.json")
				.subscribe((config: AngularConfig) => {
					this._config = config;
					resolve(true);
				});
		});
	}
}
