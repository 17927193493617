import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {CountrySetting} from "../country/CountrySetting";
import {UpdateCountrySettingRequest} from "../../request/country/UpdateCountrySettingRequest";

export class CountrySettingConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/setting";

	public getCountrySettings(): Observable<Array<CountrySetting>> | Array<CountrySetting> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/list`);
	}

	public updateCountrySetting(id: string, payload: UpdateCountrySettingRequest): Observable<CountrySetting> {
		return this.apiClient.authorizedOnly().put(`${this.apiRoute}/${id}`, payload);
	}
}
