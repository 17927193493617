import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
	name: "transformCountry"
})
export class TransformCountryPipe implements PipeTransform {

	transform(value: string, args?: any): any {
		return value
			.toLowerCase()
			.split(" ")
			.map(s => s.charAt(0).toUpperCase() + s.substring(1))
			.join(" ");
	}

}
