import { FundUploadStatus } from "../fund/FundUploadStatus";
import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {Group} from "../group/Group";
import {PagedResponse} from "../../response/PagedResponse";
import {GroupRequest} from "../../request/group/GroupRequest";
import {PagedGroupRequest} from "../../request/group/PagedGroupRequest";

export class GroupApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/group";

	public getGroups(request: PagedGroupRequest): Observable<PagedResponse<Group> | Array<Group>> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/list`, request);
	}

	public postGroup(request: GroupRequest): Observable<Group> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}`, request);
	}

	public deleteGroup(id: string): Observable<string> {
		return this.apiClient.authorizedOnly().delete(`${this.apiRoute}/${id}`, { responseType: "text" });
	}

	public updateGroup(id: string, payload: GroupRequest): Observable<Group> {
		return this.apiClient.authorizedOnly().put(`${this.apiRoute}/${id}`, payload);
	}

	public uploadFundBulkAppend(id: string, formData: FormData): Observable<string> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}/${id}/fund/bulk/append`, formData, { responseType: "text" });
	}

	public uploadFundBulkReplace(id: string, formData: FormData): Observable<string> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}/${id}/fund/bulk/replace`, formData, { responseType: "text" });
	}

	public uploadFundStatus(id: string): Observable<FundUploadStatus> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${id}/fund/status`);
	}
}
