import {Pipe, PipeTransform} from "@angular/core";
import {Role} from "../../model/data/enum/Role";

@Pipe({
	name: "role"
})
export class RolePipe implements PipeTransform {

	transform(value: any, args?: any): any {
		switch (value) {
			case Role.TOOL_USER:
				return "commons.roles.tool-user";
			case Role.CLIENT_ADMIN:
				return "commons.roles.client-admin";
			case Role.DELOITTE_ADMIN:
				return "commons.roles.deloitte-admin";
		}
	}

}
