import {Component, OnInit} from "@angular/core";
import {User} from "../../../model/data/user/User";
import {UserSessionService} from "../../../user/services/user-session.service";
import {Router} from "@angular/router";
import {StepEngineRoute} from "../../../model/data/route-definitions/StepEngineRoute";
import {AdminRoute} from "../../../model/data/route-definitions/AdminRoute";
import {Role} from "../../../model/data/enum/Role";

@Component({
	selector: "app-landing-nav",
	templateUrl: "./landing-nav.component.html",
	styleUrls: ["./landing-nav.component.scss"]
})
export class LandingNavComponent implements OnInit {

	public user: User;

	constructor(private userSessionService: UserSessionService,
				private router: Router) {
	}

	ngOnInit() {
		this.user = this.userSessionService.userDataValue;
	}

	onNavigate(route: string) {
		if (route === "iPACSTransferTax") {
			this.router.navigate([StepEngineRoute.STEP_ENGINE]);
		} else {
			if (this.user.role === Role.CLIENT_ADMIN) {
				this.router.navigate([AdminRoute.ADMIN, AdminRoute.FUNDS, "me"]);
			} else {
				this.router.navigate([AdminRoute.ADMIN, AdminRoute.FUNDS]);
			}
		}

	}

}
