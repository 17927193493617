import { FinishedExemption } from "../exemption/FinishedExemption";
import { PendingExemption } from "../exemption/PendingExemption";
import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {PagedResponse} from "../../response/PagedResponse";
import {Exemption} from "../exemption/Exemption";
import {PagedExemptionRequest} from "../../request/exemption/PagedExemptionRequest";

export class ExemptionApiConnector extends AbstractApiConnector {
	protected readonly apiRoute: string = "/api/v1/exemption";

	public getExemptions(request: PagedExemptionRequest): Observable<PagedResponse<Exemption>> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/list`, request);
	}

	public getExemption(id: string): Observable<Blob> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${id}`, null, {responseType: "blob"});
	}

	public getExemptionTemplate(): Observable<Blob> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/template.xlsx`, null, {responseType: "blob"});
	}

	public uploadExemptionFile(formData: FormData): Observable<PendingExemption> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}`, formData);
	}

	public uploadStatus(id: string): Observable<FinishedExemption> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${id}/status`);
	}

	public deleteExemptionFile(id: string): Observable<string> {
		return this.apiClient.authorizedOnly().delete(`${this.apiRoute}/${id}`, { responseType: "text" });
	}
}
