import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {ModalService} from "./services/modal.service";
import {VehiclePipe} from "./pipes/vehicle.pipe";
import {RolePipe} from "./pipes/role.pipe";
import {TransactionTypePipe} from "./pipes/transaction-type.pipe";
import {SummaryTableComponent} from "./components/summary-table/summary-table.component";
import {SummaryChartComponent} from "./components/summary-chart/summary-chart.component";
import {SummaryDetailModalComponent} from "./modals/summary-detail-modal/summary-detail-modal.component";
import {NgxSmartModalModule} from "ngx-smart-modal";
import {AdditionalMemoPipe} from "./pipes/additional-memo.pipe";
import {FundTypeTitlePipe} from "./pipes/fund-type-title.pipe";
import {VehicleTypeTooltipPipe} from "./pipes/vehicle-type-tooltip.pipe";
import {TransformCountryPipe} from "./pipes/transform-country.pipe";
import {ConfigProviderService} from "./services/config-provider.service";
import {ExportFilesService} from "./services/export-files.service";
import {NodeService} from "./services/node.service";
import {AuthGuard} from "./guards/auth.guard";

@NgModule({
	declarations: [
		// Components
		SummaryTableComponent,
		SummaryChartComponent,

		// Pipes
		VehiclePipe,
		RolePipe,
		TransactionTypePipe,
		AdditionalMemoPipe,
		FundTypeTitlePipe,
		VehicleTypeTooltipPipe,
		TransformCountryPipe,

		// Modals
		SummaryDetailModalComponent,
	],
	imports: [
		CommonModule,
		TranslateModule.forChild(),
		NgxSmartModalModule.forChild()
	],
	exports: [
		VehiclePipe,
		RolePipe,
		TransactionTypePipe,
		FundTypeTitlePipe,
		TransformCountryPipe,
		VehicleTypeTooltipPipe,
		SummaryTableComponent,
		SummaryChartComponent,
		SummaryDetailModalComponent,

	],
	providers: [
		// Services
		ModalService,
		ExportFilesService,
		NodeService,
		// Guards
		AuthGuard,
	]
})
export class CommonsModule {
}
