import {Pipe, PipeTransform} from "@angular/core";
import {TransactionType} from "../../model/data/enum/TransactionType";

@Pipe({
	name: "transactionType"
})
export class TransactionTypePipe implements PipeTransform {

	transform(value: any, args?: any): any {
		switch (value) {
			case TransactionType.CONTRIBUTION:
				return "step-engine.step-engine-transition-types.contribution";
			case TransactionType.DISTRIBUTION:
				return "step-engine.step-engine-transition-types.distribution";
			case TransactionType.MERGER:
				return "step-engine.step-engine-transition-types.merge";
			case TransactionType.SWITCH:
				return "step-engine.step-engine-transition-types.switch";
		}
	}

}
