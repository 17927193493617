import {Inject, Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

import {ApiCommunicationService} from "../../model/services/ApiCommunicationService";
import {Domicile} from "../../model/data/domicile/Domicile";

@Injectable()
export class NodeService {

	public _jurisdictions$ = new BehaviorSubject<Array<Domicile>>(null);

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {}

	public fetchJurisdictions(): Promise<boolean> {
		return new Promise(resolve => {
			return this.api.node().getJurisdictions().subscribe((response: Array<Domicile>) => {
				this._jurisdictions$.next(response);
				return resolve(true);
			});
		});
	}

	get jurisdictions(): BehaviorSubject<Array<Domicile>> {
		return this._jurisdictions$;
	}

	public getJurisdictions(): Array<Domicile> {
		return this._jurisdictions$.getValue();
	}

}
