import {Injectable} from "@angular/core";
import {NgxSmartModalService} from "ngx-smart-modal";
import {Fund} from "../../model/data/fund/Fund";
import {Group} from "../../model/data/group/Group";
import {User} from "../../model/data/user/User";
import {TransactionType} from "../../model/data/enum/TransactionType";
import {Domicile} from "../../model/data/domicile/Domicile";
import {Exemption} from "../../model/data/exemption/Exemption";
import {CountrySetting} from "../../model/data/country/CountrySetting";

export enum Modals {
	// excel upload in step engine
	UPLOAD = "upload",
	// other domicile warning
	WARNING_OTHER = "warningOther",
	// admin fund manipulation
	DELETE_FUND = "deleteFund",
	ADD_FUND = "addFund",
	ADD_CHILD_FUND = "addChildFund",
	EDIT_FUND = "editFund",
	UPLOAD_FUNDS = "uploadFunds",
	// admin group manipulation
	ADD_GROUP = "addGroup",
	DELETE_GROUP = "deleteGroup",
	EDIT_GROUP = "editGroup",
	CONFIRM_EDIT_GROUP = "confirmEditGroup",
	// admin user manipulation
	ADD_USER = "addUser",
	REMOVE_USER = "removeUser",
	DELETE_USER = "deleteUser",
	EDIT_USER = "editUser",
	// summary
	SUMMARY_DETAILS = "summaryDetails",
	// files
	UPLOAD_FILE = "uploadFile",
	DELETE_FILE = "deleteFile",
	// settings
	EDIT_SETTING = "editSetting"
}

@Injectable()
export class ModalService {

	constructor(public ngxSmartModalService: NgxSmartModalService) {}

	public openUploadModal(fund1: string, transactionType: TransactionType, fund2?: string, ) {
		this.ngxSmartModalService.setModalData({fund1, fund2, transactionType}, Modals.UPLOAD);
		this.ngxSmartModalService.getModal(Modals.UPLOAD).open();
	}

	public openWarningOtherModal() {
		this.ngxSmartModalService.getModal(Modals.WARNING_OTHER).open();
	}

	public openDeleteFundModal(fund: Fund) {
		this.ngxSmartModalService.setModalData({ fund }, Modals.DELETE_FUND);
		this.ngxSmartModalService.getModal(Modals.DELETE_FUND).open();
	}

	public openAddFundModal() {
		this.ngxSmartModalService.getModal(Modals.ADD_FUND).open();
	}

	public openAddChildFundModal(fund: Fund) {
		this.ngxSmartModalService.setModalData({ fund }, Modals.ADD_CHILD_FUND);
		this.ngxSmartModalService.getModal(Modals.ADD_CHILD_FUND).open();
	}

	public openEditFundModal(fund: Fund, isChildFund) {
		this.ngxSmartModalService.setModalData({ fund, isChildFund }, Modals.EDIT_FUND);
		this.ngxSmartModalService.getModal(Modals.EDIT_FUND).open();
	}

	public openAddGroupModal() {
		this.ngxSmartModalService.getModal(Modals.ADD_GROUP).open();
	}

	public openDeleteGroupModal(group: Group) {
		this.ngxSmartModalService.setModalData({ group }, Modals.DELETE_GROUP);
		this.ngxSmartModalService.getModal(Modals.DELETE_GROUP).open();
	}

	public openEditGroupModal(group: Group) {
		this.ngxSmartModalService.setModalData({ group }, Modals.EDIT_GROUP);
		this.ngxSmartModalService.getModal(Modals.EDIT_GROUP).open();
	}

	public openConfirmEditGroupModal() {
		this.ngxSmartModalService.getModal(Modals.CONFIRM_EDIT_GROUP).open();
	}

	public openAddUserModal() {
		this.ngxSmartModalService.getModal(Modals.ADD_USER).open();
	}

	public openRemoveUserModal(user: User) {
		this.ngxSmartModalService.setModalData({ user }, Modals.REMOVE_USER);
		this.ngxSmartModalService.getModal(Modals.REMOVE_USER).open();
	}

	public openDeleteUserModal(user: User) {
		this.ngxSmartModalService.setModalData({user}, Modals.DELETE_USER);
		this.ngxSmartModalService.getModal(Modals.DELETE_USER).open();
	}

	public openEditUserModal(user: User) {
		this.ngxSmartModalService.setModalData({ user }, Modals.EDIT_USER);
		this.ngxSmartModalService.getModal(Modals.EDIT_USER).open();
	}

	public openSummaryDetailsModal(jurisdiction: Domicile) {
		this.ngxSmartModalService.setModalData({ jurisdiction }, Modals.SUMMARY_DETAILS);
		this.ngxSmartModalService.getModal(Modals.SUMMARY_DETAILS).open();
	}

	public openUploadFundsModal() {
		this.ngxSmartModalService.getModal(Modals.UPLOAD_FUNDS).open();
	}

	public openUploadFileModal() {
		this.ngxSmartModalService.getModal(Modals.UPLOAD_FILE).open();
	}

	public openDeleteFileModal(exemption: Exemption) {
		this.ngxSmartModalService.setModalData({ exemption }, Modals.DELETE_FILE);
		this.ngxSmartModalService.getModal(Modals.DELETE_FILE).open();
	}

	public openEditSettingModal(setting: CountrySetting) {
		this.ngxSmartModalService.setModalData({ setting }, Modals.EDIT_SETTING);
		this.ngxSmartModalService.getModal(Modals.EDIT_SETTING).open();
	}

	public closeModal(id: Modals) {
		this.ngxSmartModalService.resetModalData(id);
		this.ngxSmartModalService.close(id);
	}
}
