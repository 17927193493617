import {Observable} from "rxjs";

import {AbstractApiConnector} from "./AbstractApiConnector";
import {NodeRequest} from "../../request/node/NodeRequest";
import {NodeResponse} from "../../response/node/NodeResponse";
import {Domicile} from "../domicile/Domicile";

export class NodeApiConnector extends AbstractApiConnector {
	protected readonly apiRoute: string = "/api/v1/node";

	public getNodes(pagedNodeRequest: NodeRequest): Observable<NodeResponse> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/list`, pagedNodeRequest);
	}

	public getJurisdictions(): Observable<Array<Domicile>> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/jurisdictions`);
	}

}
