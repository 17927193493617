import {Pipe, PipeTransform} from "@angular/core";
import {Vehicle, VehicleType} from "../../model/data/enum/Vehicle";

@Pipe({
	name: "vehicle"
})
export class VehiclePipe implements PipeTransform {

	transform(value: any, args?: any): any {
		switch (value) {
			case VehicleType.OEIC:
				return Vehicle.OEIC.translation;
			case VehicleType.UNIT_TRUST:
				return Vehicle.UNIT_TRUST.translation;
			case VehicleType.ACS:
				return Vehicle.ACS.translation;
			case VehicleType.EU_CF:
				return Vehicle.EU_CF.translation;
			case VehicleType.LIFE_CO:
				return Vehicle.LIFE_CO.translation;
			case VehicleType.US_PARTNERSHIP:
				return Vehicle.US_PARTNERSHIP.translation;
			case VehicleType.US_BUSINESS_TRUST:
				return Vehicle.US_BUSINESS_TRUST.translation;
			case VehicleType.US_CORPORATE_TRUST:
				return Vehicle.US_CORPORATE_TRUST.translation;
			case VehicleType.US_OPEN_ENDED_CORPORATE:
				return Vehicle.US_OPEN_ENDED_CORPORATE.translation;
			case VehicleType.US_CLOSE_ENDED_CORPORATE:
				return Vehicle.US_CLOSE_ENDED_CORPORATE.translation;
		}
	}

}
