import {Pipe, PipeTransform} from "@angular/core";
import {Domicile} from "../../model/data/domicile/Domicile";

@Pipe({
	name: "additionalMemo"
})
export class AdditionalMemoPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		switch (value) {
			case Domicile.GB.code:
				return "commons.additional-memo.GB";
			case Domicile.IE.code:
				return "commons.additional-memo.IE";
			case Domicile.FR.code:
				return "commons.additional-memo.FR";
			case Domicile.IT.code:
				return "commons.additional-memo.IT";
			case Domicile.HK.code:
				return "commons.additional-memo.HK";
			default:
				return "";
		}
	}

}
