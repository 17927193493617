export class Domicile {
	static readonly GB = new Domicile("Great Britain", "GB");
	static readonly HK = new Domicile("Hong Kong", "HK");
	static readonly LU = new Domicile("Luxembourg", "LU");
	static readonly US = new Domicile("United States", "US");
	static readonly CH = new Domicile("Switzerland", "CH");
	static readonly IE = new Domicile("Ireland", "IE");
	static readonly ZA = new Domicile("South Africa", "ZA");
	static readonly CA = new Domicile("Canada", "CA");
	static readonly AU = new Domicile("Australia", "AU");
	static readonly NL = new Domicile("Netherlands", "NL");
	static readonly SG = new Domicile("Singapore", "SG");
	static readonly OT = new Domicile("Other", "OT");

	// only in results page
	static readonly FR = new Domicile("France", "FR");
	static readonly IT = new Domicile("Italy", "IT");

	constructor (public name: string, public code: string) {
		this.name = name;
		this.code = code;
	}
}
