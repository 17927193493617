import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {PagedResponse} from "../../response/PagedResponse";

export class AuthApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/auth";

	public getApiKey(token: string): Observable<PagedResponse<any>> {
		return this.apiClient.post(`${this.apiRoute}/login`, {token: token});
	}
	public getRefreshKey(token: string): Observable<PagedResponse<any>> {
		return this.apiClient.post(`${this.apiRoute}/refresh`, {token: token});
	}
}
