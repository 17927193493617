import {Domicile} from "../domicile/Domicile";

export enum VehicleType {
	OEIC = 1,
	UNIT_TRUST = 2,
	ACS = 3,
	EU_CF = 4,
	LIFE_CO = 5,
	US_PARTNERSHIP = 6,
	US_BUSINESS_TRUST = 7,
	US_CORPORATE_TRUST = 8,
	US_OPEN_ENDED_CORPORATE = 9,
	US_CLOSE_ENDED_CORPORATE = 10
}

export class Vehicle {
	static readonly UNIT_TRUST =
		new Vehicle(
			VehicleType.UNIT_TRUST,
			"commons.vehicles.ut",
			"user circle",
			[Domicile.GB, Domicile.IE, Domicile.HK, Domicile.US, Domicile.ZA, Domicile.AU, Domicile.CA, Domicile.SG, Domicile.OT]);
	static readonly OEIC =
		new Vehicle(
			VehicleType.OEIC,
			"commons.vehicles.oeic",
			"handshake outline",
			[Domicile.GB, Domicile.IE, Domicile.LU, Domicile.HK, Domicile.OT],
			"commons.vehicles.popup.oeic");
	static readonly EU_CF =
		new Vehicle(
			VehicleType.EU_CF,
			"commons.vehicles.contractual",
			"clipboard outline",
			[Domicile.LU, Domicile.IE, Domicile.CH, Domicile.NL, Domicile.OT],
			"commons.vehicles.popup.contractual");
	static readonly ACS =
		new Vehicle(
			VehicleType.ACS,
			"commons.vehicles.acs",
			"building",
			[Domicile.GB],
			"commons.vehicles.popup.acs");
	static readonly LIFE_CO =
		new Vehicle(
			VehicleType.LIFE_CO,
			"commons.vehicles.life-co",
			"share alternate",
			[Domicile.GB, Domicile.IE, Domicile.US, Domicile.OT],
			"commons.vehicles.popup.life-co");
	static readonly US_PARTNERSHIP =
		new Vehicle(
			VehicleType.US_PARTNERSHIP,
			"commons.vehicles.us-partnership",
			"balance scale",
			[Domicile.US]);
	static readonly US_BUSINESS_TRUST =
		new Vehicle(
			VehicleType.US_BUSINESS_TRUST,
			"commons.vehicles.us-business-trust",
			"suitcase",
			[Domicile.US]);
	static readonly US_CORPORATE_TRUST =
		new Vehicle(
			VehicleType.US_CORPORATE_TRUST,
			"commons.vehicles.us-corporate-trust",
			"chart bar outline",
			[Domicile.US]);
	static readonly US_OPEN_ENDED_CORPORATE =
		new Vehicle(
			VehicleType.US_OPEN_ENDED_CORPORATE,
			"commons.vehicles.us-open-ended-corporate",
			"warehouse",
			[Domicile.US]);
	static readonly US_CLOSE_ENDED_CORPORATE =
		new Vehicle(
			VehicleType.US_CLOSE_ENDED_CORPORATE,
			"commons.vehicles.us-close-ended-corporate",
			"industry",
			[Domicile.US]);

	private constructor(
		public readonly id: number,
		public readonly translation: string,
		public readonly icon: string,
		public readonly allowedDomiciles: Array<Domicile>,
		public readonly popup?: string) {
			this.id = id;
			this.translation = translation;
			this.icon = icon;
			this.allowedDomiciles = allowedDomiciles;
			this.popup = popup;
	}
}

