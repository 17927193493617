import {Domicile} from "../domicile/Domicile";
import BigNumber from "bignumber.js";

export class Calculation {
	actualTaxDue: string | BigNumber;
	bestPracticeTaxDue: string | BigNumber;
	breakdown: Array<CalculationByJurisdiction>;
	currency: Currency;
	savings: string | BigNumber;
	chartData?: Array<ChartDataElement>;
	auditLogId?: string;

	constructor() {}

	public static transform(calculation: Calculation): Calculation {
		calculation.actualTaxDue = new BigNumber(calculation.actualTaxDue);
		calculation.bestPracticeTaxDue = new BigNumber(calculation.bestPracticeTaxDue);
		calculation.savings = new BigNumber(calculation.savings);
		calculation.breakdown.forEach(c => {
			c.actualTaxDue = new BigNumber(c.actualTaxDue);
			c.bestPracticeTaxDue = new BigNumber(c.bestPracticeTaxDue);
			c.savings = new BigNumber(c.savings);
		});
		return calculation;
	}
}

export class CalculationByJurisdiction {
	actualTaxDue: string | BigNumber;
	bestPracticeTaxDue: string | BigNumber;
	jurisdiction: Domicile;
	savings: string | BigNumber;
	nextStep: string;
	chartData?: Array<ChartDataElement>;
}

export class Currency {
	code: string;
	symbol: string;
}

export class ChartDataElement {
	name: string;
	value: number;
}
