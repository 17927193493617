import {Pipe, PipeTransform} from "@angular/core";
import {TransactionType} from "../../model/data/enum/TransactionType";

@Pipe({
	name: "fundTypeTitle"
})
export class FundTypeTitlePipe implements PipeTransform {

	transform(value: any, args?: any): any {
		switch (value) {
			case TransactionType.MERGER:
				if (args === 1) {
					return "step-engine.step-engine-questions.discontinuing-fund";
				} else {
					return "step-engine.step-engine-questions.continuing-fund";
				}
			case TransactionType.SWITCH:
				if (args === 1) {
					return "step-engine.step-engine-questions.legacy-fund";
				} else {
					return "step-engine.step-engine-questions.target-fund";
				}
			default:
				return false;
		}
	}

}
