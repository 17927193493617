import {Configuration, DefaultHeaders, NgxOAuthClient, NgxOAuthResponse} from "@bkoti/ngx-oauth-client";
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injector} from "@angular/core";
import {Observable, throwError as observableThrowError} from "rxjs";
import {RefreshRequest} from "../../request/auth/RefreshRequest";
import {catchError, map, switchMap} from "rxjs/operators";
import {AuthorizationError} from "../exceptions/AuthorizationError";
import {LoginResponse} from "../../response/auth/LoginResponse";
import {LoginRequest} from "../../request/auth/LoginRequest";
import {CookieService} from "ngx-cookie-service";
import {ConfigProviderService} from "../../../commons/services/config-provider.service";

enum API_ROUTES {
	AUTH = "/api/v1/auth"
}

// @Configuration({
// 	// base api
// 	host: environment.apiUrl,
// 	// local storage prefix
// 	storage_prefix: "diamond_",
// 	// default client id
// 	key: ""
// })
@DefaultHeaders({
	"Content-Type": "application/json",
	"Accept": "application/json"
})
export class OAuthApiClient extends NgxOAuthClient {

	/**
	 * Constructor - all fields are injected.
	 * @param {HttpClient} http
	 * @param {Injector} injector
	 * @param {CookieService} cookieService
	 */
	constructor(http: HttpClient, private injector: Injector, private cookieService: CookieService, private config: ConfigProviderService) {

		// pass the injected http client to super class
		super(http);

	}

	/**
	 * Ensures that user is logged in before firing a request.
	 * Throws {AuthorizationError} if no valid token found in local storage.
	 * @returns {OAuthApiClient} The service itself.
	 */
	public authorizedOnly(): OAuthApiClient {

		// check if access token is available
		if (!this.fetchToken("access_token")) {
			throw new AuthorizationError("Can not access this functionality. Please log in.");
		}

		// return the service itself
		return this;
	}

	/**
	 * Translates {AuthenticationResponse} to {NgxOAuthResponse}.
	 * @param {LoginResponse} response The response to transform.
	 * @returns {NgxOAuthResponse} The transformed response
	 */
	protected translateAuthenticationResponse(response: LoginResponse): NgxOAuthResponse {
		return <NgxOAuthResponse>{
			token_type: "Bearer",
			access_token: response.accessToken,
			refresh_token: response.refreshToken
		};
	}

	// requests
	/**
	 * Login API endpoint.
	 * @param {LoginRequest} data Object containing DBoard Token
	 * @returns {Observable<LoginResponse>} Response object
	 */
	private endpoint_auth_login(): Observable<LoginResponse> {
		return this.http.post<LoginResponse>(this.getConfig().host + API_ROUTES.AUTH + "/login", null, {withCredentials: true});
	}

	/**
	 * Refresh token api endpoint.
	 * @param {RefreshRequest} data Request body containing token.
	 * @returns {Observable<LoginResponse>} Response object
	 */
	private endpoint_auth_refresh_token(data: RefreshRequest): Observable<LoginResponse> {

		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				"Authorization": "Bearer" + " " + this.fetchToken("access_token")
			})
		};

		return this.http.post<LoginResponse>(
			this.getConfig().host + API_ROUTES.AUTH + "/refresh",
			// set date and headers
			data,
			httpOptions
		);
	}

	/**
	 * Rewritten token acquiring process to match our api.
	 * @param data login data - only if grant type is "password"
	 * @param grant_type
	 * @returns {Observable<any>}
	 */
	public getToken(grant_type?: string, data?: any): Observable<NgxOAuthResponse> {
		// console.log("getToken");
		if (grant_type && ["client_credentials", "authorization_code", "password", "refresh_token", "dBoard"].indexOf(grant_type) === -1) {
			throw new Error(`Grant type ${grant_type} is not supported`);
		}

		// refresh token request
		if (grant_type === "refresh_token") {

			// fetch refresh token and token type
			const refresh_token = this.fetchToken("refresh_token");
			// const token_type = this.fetchToken("token_type") || "Bearer";

			// check for validity of the refresh token
			if (refresh_token === undefined || refresh_token === null || refresh_token.trim() === "") {
				throw new Error("Could not refresh token: no refresh_token to use");
			}

			// Refresh token request (refresh_token grant type)
			return this.endpoint_auth_refresh_token({token: refresh_token}).pipe(map((res: LoginResponse) => {

				// translate the result parameters
				const transformed = this.translateAuthenticationResponse(res);

				// set and store client id
				// this.setClientId(res.clientId);

				// set token
				this.setToken(transformed);

				// return the result
				return transformed;

			}));

			// normal login request using username and password
		} else if (grant_type === "password") {

			// fire login request trough api
			return this.endpoint_auth_login().pipe(
				// transform results to match the requirements of oauth module
				map((res: LoginResponse) => {

					// translate the result parameters
					const transformed = this.translateAuthenticationResponse(res);

					// set token
					this.setToken(transformed);

					// return the result
					return transformed;

				}));

			// other grant types
		} else if (grant_type === "dBoard") {
			// fire login request trough api
			return this.endpoint_auth_login().pipe(
				// transform results to match the requirements of oauth module
				map((res: LoginResponse) => {

					// translate the result parameters
					const transformed = this.translateAuthenticationResponse(res);

					// set token
					this.setToken(transformed);

					// return the result
					return transformed;

				}));

			// other grant types
		} else {
			// Currently no other grant types are implemented
			throw new Error(`The following grant type is not implemented: ${grant_type}.`);
		}
	}

	// interceptors
	/**
	 * Request interceptor. Intercepts request for adding auth header.
	 * @param request The request to intercept.
	 * @returns {any}
	 */
	requestInterceptor(request) {

		// get token and token type
		const token = this.fetchToken("access_token");
		const token_type = this.fetchToken("token_type") || "Bearer";

		// add token  to the request if it is batchByYearsAndDays
		if (token) {
			return request.setHeaders({
				Authorization: token_type + " " + token
			});
		}

		return request;
	}

	errorInterceptor(request: any, error: any): Observable<any> {
		// special case for 401 Unauthorized
		if (error.status === 401) {

			// refresh token and re-fire request
			return this.getToken("dBoard").pipe(
				// use new token to re-fire request
				switchMap(token => {

					// re-fire original request
					return this.getClient().request(
						request.method, request.url,
						this.requestInterceptor(request.setHeaders({Authorization: `${token.token_type} ${token.access_token}`}))
					);

				}),

				// catch token refresh errors and invalidate tokens
				catchError(refreshTokenError => {

					// console.log("Could not refresh token: " + refreshTokenError);

					// clear stored token
					this.clearToken();

					// return the original error
					return observableThrowError(error);
				}),
			);
		}

		// normal case: pass trough the error
		return observableThrowError(error);
	}


	getConfig(): any {
		return this.config.getConfiguration();
	}

}
