import {Component, HostListener, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NavigationEnd, Router} from "@angular/router";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

	public isScrolled = false;

	constructor(private translate: TranslateService,
				private router: Router) {
		this.initTranslations();
	}

	/*Scroll event handler*/
	@HostListener("window:scroll", ["$event"])
	private onWindowScroll(): void {
		this.isScrolled = window.pageYOffset !== 0;
	}

	ngOnInit(): void {
		this.setupRouterEvents();
	}

	private initTranslations() {
		// translation service defaults
		this.translate.setDefaultLang("en");
		this.translate.use("en");
	}

	private setupRouterEvents(): void {
		// scroll to the top of the page when navigation happens
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});
	}

}
