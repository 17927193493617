import {AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import BigNumber from "bignumber.js";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

import {Modals, ModalService} from "../../services/modal.service";
import {ApiCommunicationService} from "../../../model/services/ApiCommunicationService";
import {ChargeabilityResponse, Isin} from "../../../model/response/portfolio/ChargeabilityResponse";
import {Domicile} from "../../../model/data/domicile/Domicile";
import {SecurityType} from "../../../model/data/enum/SecurityType";

// Set theme
am4core.useTheme(am4themes_animated);

// Remove branding
am4core.options.commercialLicense = true;

@Component({
	selector: "app-summary-detail-modal",
	templateUrl: "./summary-detail-modal.component.html",
	styleUrls: ["./summary-detail-modal.component.scss"]
})
export class SummaryDetailModalComponent implements OnInit, AfterViewInit, OnDestroy {

	private unsubscribe$ = new Subject<void>();

	@Input() auditLogId: string;

	@ViewChild("pieChart") chartElement: ElementRef<HTMLElement>;
	@ViewChild("lineChart") chartElement2: ElementRef<HTMLElement>;

	private chart: any;
	private chart2: any;

	public breakdownLength: number;

	public jurisdiction: Domicile;

	constructor(public modal: ModalService,
				@Inject(ApiCommunicationService) private api: ApiCommunicationService) {}

	ngOnInit() {
	}

	ngAfterViewInit(): void {
		this.modal.ngxSmartModalService.getModal(Modals.SUMMARY_DETAILS).onDataAdded
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((data) => {
				this.jurisdiction = data.jurisdiction;
			});

		this.modal.ngxSmartModalService.getModal(Modals.SUMMARY_DETAILS).onOpen
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(() => {
				// fetch data from the BE
				this.api.auditLog().fetchChargeability(this.auditLogId, this.jurisdiction ? this.jurisdiction.code : null)
					.subscribe((data: ChargeabilityResponse) => {
						this.breakdownLength = data.breakdown.length;
						data.breakdown = this.convertBreakdown(data.breakdown);
						this.createPieChart(data);
						this.createLineChart(data);
			});

		});
		this.modal.ngxSmartModalService.getModal(Modals.SUMMARY_DETAILS).onClose
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((modal) => {
				this.chart.dispose();
				this.chart2.dispose();
				this.chart = null;
				this.chart2 = null;
				modal.removeData();
		});
	}

	convertBreakdown(isins: Array<Isin>): Array<Isin> {
		isins.forEach(isin => {
			isin.actualTaxDue = new BigNumber(isin.actualTaxDue).toNumber();
		});
		return isins;
	}

	calculatePercentage(all: ChargeabilityResponse, toCalculate: number) {
		return toCalculate ? toCalculate / (all.nonChargeable + all.chargeable + all.debt + all.other + all.synthetic) : 0;
	}

	createPieChart(response: ChargeabilityResponse) {
		const data = [
			{
				type: "Chargeable equities",
				percent: this.calculatePercentage(response, response.chargeable)
			},
			{
				type: "Non-chargeable equities",
				percent: this.calculatePercentage(response, response.nonChargeable)
			},
			{
				type: "Synthetic",
				percent: this.calculatePercentage(response, response.synthetic)
			},
			{
				type: "Debt",
				percent: this.calculatePercentage(response, response.debt)
			},
			{
				type: "Other",
				percent: this.calculatePercentage(response, response.other)
			}
		];

		this.chart = am4core.create(this.chartElement.nativeElement, am4charts.PieChart);

		// Add data
		this.chart.data = data;

		// Add and configure Series
		const pieSeries = this.chart.series.push(new am4charts.PieSeries());
		pieSeries.dataFields.value = "percent";
		pieSeries.dataFields.category = "type";
		pieSeries.slices.template.stroke = am4core.color("#fff");
		pieSeries.slices.template.strokeWidth = 2;
		pieSeries.slices.template.strokeOpacity = 1;
		// pieSeries.colors.list = [am4core.color("#005587"), am4core.color("#81bc00")];

		// This creates initial animation
		pieSeries.hiddenState.properties.opacity = 1;
		pieSeries.hiddenState.properties.endAngle = -90;
		pieSeries.hiddenState.properties.startAngle = -90;

		// disable labels and ticks and tooltip and movement
		pieSeries.labels.template.disabled = true;
		pieSeries.ticks.template.disabled = true;
		pieSeries.slices.template.tooltipText = "";
		pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;

		// adding legend
		this.chart.legend = new am4charts.Legend();
	}

	createLineChart(response: ChargeabilityResponse) {
		/* Create chart instance */
		this.chart2 = am4core.create(this.chartElement2.nativeElement, am4charts.XYChart);

		response.breakdown.sort((a, b) => (a.actualTaxDue > b.actualTaxDue) ? 1 : -1);

		// transform ISIN names, attach security types
		response.breakdown = this.transformISINNames(response.breakdown);

		response.breakdown.map((element) => {
			element.actualTaxDue = Math.round(element.actualTaxDue as number);
		});

		this.chart2.data = response.breakdown;

		const categoryAxis = this.chart2.yAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "isin";
		categoryAxis.renderer.grid.template.location = 0;

		const valueAxis = this.chart2.xAxes.push(new am4charts.ValueAxis());
		valueAxis.min = 0;

		const series = this.chart2.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueX = "actualTaxDue";
		series.dataFields.categoryY = "isin";
		series.columns.template.fill = am4core.color("#00aaaa"); // fill

		const valueLabel = series.bullets.push(new am4charts.LabelBullet());
		valueLabel.label.text = "{actualTaxDue}";
		valueLabel.label.fontSize = 14;
		valueLabel.label.horizontalCenter = "left";
		valueLabel.label.dx = 10;
		valueLabel.label.hideOversized = false;
		valueLabel.label.truncate = false;

		this.chart2.maskBullets = false;
		this.chart2.paddingRight = 30;
	}

	private transformISINNames(breakdown: Array<Isin>) {
		breakdown.forEach(isin => {
			switch (isin.type) {
				case SecurityType.DEBT:
					isin.isin = `${isin.isin} (Debt)`;
					break;
				case SecurityType.EQUITY:
					isin.isin = `${isin.isin} (Equity)`;
					break;
				case SecurityType.SYNTHETIC:
					isin.isin = `${isin.isin} (Synthetic)`;
					break;
				case SecurityType.OTHER:
					isin.isin = `${isin.isin} (Other)`;
					break;
			}
		});
		return breakdown;
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

}
