import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class LandingMapService {

	private _selectedJurisdiction: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor() {}

	get selectedJurisdiction(): BehaviorSubject<string> {
		return this._selectedJurisdiction;
	}

	public setSelectedJurisdiction(code: string) {
		this._selectedJurisdiction.next(code);
	}
}
