import {Inject, Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {AbstractApiConnector} from "../data/connectors/AbstractApiConnector";
import {HttpClient} from "@angular/common/http";
import {OAuthApiClient} from "../data/utility/OAuthApiClient";
import {ApiConnectorWithIdAlreadyPresentError} from "../data/exceptions/ApiConnectorWithIdAlreadyPresentError";
import {AuthApiConnector} from "../data/connectors/AuthApiConnector";
import {FundApiConnector} from "../data/connectors/FundApiConnector";
import {PortfolioApiConnector} from "../data/connectors/PortfolioApiConnector";
import {NodeApiConnector} from "../data/connectors/NodeApiConnector";
import {UserApiConnector} from "../data/connectors/UserApiConnector";
import {GroupApiConnector} from "../data/connectors/GroupApiConnector";
import {AuditLogApiConnector} from "../data/connectors/AuditLogApiConnector";
import {ConfigProviderService} from "../../commons/services/config-provider.service";
import {ExemptionApiConnector} from "../data/connectors/ExemptionApiConnector";
import {TaxRateApiConnector} from "../data/connectors/TaxRateApiConnector";
import {CountrySettingConnector} from "../data/connectors/CountrySettingConnector";

/**
 * API Connectors have a unique enum identifier assigned to them.
 */
export enum Connector {
	AUTH = "Auth",
	FUND = "Fund",
	PORTFOLIO = "Portfolio",
	NODE = "Node",
	USER = "User",
	GROUP = "Group",
	AUDIT_LOG = "Audit Log",
	EXEMPTION = "Exemption",
	TAX_RATE = "Tax Rate",
	COUNTRY_SETTING = "Country Setting"
}

/**
 * This service is responsible for all the api communication towards the backend, acting as a hub for all the different connectors.
 */
@Injectable()
export class ApiCommunicationService {

	// // api base url
	// private apiBaseUrl: string = environment.apiUrl;

	// api base url
	private apiBaseUrl = "-";

	// connector list
	private connectors: Map<Connector, AbstractApiConnector>;

	constructor(@Inject(HttpClient) private http: HttpClient,
				@Inject(OAuthApiClient) private apiClient: OAuthApiClient) {

		// create the map of connectors
		this.connectors = new Map<Connector, AbstractApiConnector>();
		// register all the connectors
		this.registerConnector(Connector.AUTH, new AuthApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.FUND, new FundApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.PORTFOLIO, new PortfolioApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.NODE, new NodeApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.USER, new UserApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.GROUP, new GroupApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.AUDIT_LOG, new AuditLogApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.EXEMPTION, new ExemptionApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.TAX_RATE, new TaxRateApiConnector(http, apiClient, this.apiBaseUrl));
		this.registerConnector(Connector.COUNTRY_SETTING, new CountrySettingConnector(http, apiClient, this.apiBaseUrl));
	}

	/**
	 * This function registers a connector to the connector pool.
	 * @param {Connector} id The unique identifier for a connector.
	 * @param {AbstractApiConnector} connector The connector to register.
	 */
	private registerConnector(id: Connector, connector: AbstractApiConnector) {

		// check if id is not already registered
		if (this.connectors.has(id)) {
			throw new ApiConnectorWithIdAlreadyPresentError("A connector with ID '" + id + "' has already been registered.");
		}

		// register connector with the given ID.
		try {
			this.connectors.set(id, connector);
		} catch (e) {
			console.error("Could not register connector: " + e);
		}
	}

	protected getConnector(connector: Connector): AbstractApiConnector {

		// check if connector is registered
		if (!this.connectors.has(connector)) {
			throw new Error("No connector is registered for: " + connector);
		}

		return this.connectors.get(connector);
	}

	// API connector getters
	public auth(): AuthApiConnector {
		return (<AuthApiConnector> this.getConnector(Connector.AUTH));
	}

	public fund(): FundApiConnector {
		return (<FundApiConnector> this.getConnector(Connector.FUND));
	}

	public portfolio(): PortfolioApiConnector {
		return (<PortfolioApiConnector> this.getConnector(Connector.PORTFOLIO));
	}

	public node(): NodeApiConnector {
		return (<NodeApiConnector> this.getConnector(Connector.NODE));
	}

	public user(): UserApiConnector {
		return (<UserApiConnector> this.getConnector(Connector.USER));
	}

	public group(): GroupApiConnector {
		return (<GroupApiConnector> this.getConnector(Connector.GROUP));
	}

	public auditLog(): AuditLogApiConnector {
		return (<AuditLogApiConnector> this.getConnector(Connector.AUDIT_LOG));
	}

	public exemption(): ExemptionApiConnector {
		return (<ExemptionApiConnector> this.getConnector(Connector.EXEMPTION));
	}

	public taxRate(): TaxRateApiConnector {
		return (<TaxRateApiConnector> this.getConnector(Connector.TAX_RATE));
	}

	public countrySetting(): CountrySettingConnector {
		return (<CountrySettingConnector> this.getConnector(Connector.COUNTRY_SETTING));
	}

}
