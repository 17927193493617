import {Inject, Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {LoginRequest} from "../../model/request/auth/LoginRequest";
import {distinctUntilChanged, share} from "rxjs/operators";
import {ApiCommunicationService} from "../../model/services/ApiCommunicationService";
import {OAuthApiClient} from "../../model/data/utility/OAuthApiClient";
import {HttpErrorResponse} from "@angular/common/http";
import {User} from "../../model/data/user/User";
import {ConfigProviderService} from "../../commons/services/config-provider.service";

/**
 * Different reasons for login rejection.
 */
export enum LoginRejectionReason {
	ACCEPTED = "",
	UNKNOWN = "user.login-page.login-error-unknown",
	SERVICE_UNAVAILABLE = "user.login-page.login-error-service-unavailable",
	BAD_CREDENTIALS = "user.login-page.login-error-wrong-credentials",
	TOKEN_EXPIRED = "user.login-page.login-error-token-expired"
}

@Injectable()
export class UserSessionService {

	// object keeping the actual user data
	private _userData: BehaviorSubject<any> = new BehaviorSubject(undefined);

	// object keeping the actual user logged in status
	private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService,
				@Inject(OAuthApiClient) private oAuthApiClient: OAuthApiClient,
				private config: ConfigProviderService) {

		// keep track of isLoggedIn value based on the user object
		this._userData.pipe(share()).subscribe(userObject => {
			this._isLoggedIn.next(userObject != null);
		});

	}

	/**
	 * User login action using email:password combo.
	 * @param request email und password wrapper object
	 * @returns {Promise<LoginRejectionReason>} Returns promise with the login rejection reason.
	 */
	public login(request: LoginRequest): Promise<LoginRejectionReason> {
		return new Promise<any>((resolve, reject) => {
			this.oAuthApiClient.getToken("password", request).subscribe(() => {
				// start user session
				this.startSession().then((sessionResult) => {
					// resolve with result of session staring
					resolve(sessionResult);
				}).catch((sessionRejection) => {
					// reject
					reject(sessionRejection);
				});
			}, (error: HttpErrorResponse) => {
				// Wrong username or password
				if (error.status === 401) {
					reject(LoginRejectionReason.BAD_CREDENTIALS);
					// Service unavailable
				} else if (error.status === 0 || error.status === 503) {
					reject(LoginRejectionReason.SERVICE_UNAVAILABLE);
					// Unknown issues
				} else {
					reject(LoginRejectionReason.UNKNOWN);
				}
			});
		});
	}

	/**
	 * This method starts user session based on the token stored in local storage.
	 * This method is executed on startup, so user is automatically logged in while the token is valid.
	 * @returns {Promise<LoginRejectionReason>} Returns a promise containing the status of the
	 */
	public startSession(): Promise<LoginRejectionReason> {

		return new Promise<any>(async (resolve, reject) => {

			// fetching configuration files
			// console.log("startSession: fetch configuration");

			await this.config.load();

			// console.log("startSession: sending current access token to BE");

			// get user
			this.api.user().getLoggedInUser().subscribe((response: User) => {
				this._userData.next(response);
				resolve(LoginRejectionReason.ACCEPTED);
			}, (error: HttpErrorResponse) => {
				// Token is invalid
				if (error.status === 401) {
					reject(LoginRejectionReason.TOKEN_EXPIRED);
					// No response from server
				} else if (error.status === 0 || error.status === 503) {
					reject(LoginRejectionReason.SERVICE_UNAVAILABLE);
					// Other issue
				} else {
					reject(LoginRejectionReason.UNKNOWN);
				}
			});

		});
	}

	/**
	 * Starts session, but without rejecting promise, so it won't prevent the whole application from loading.
	 */
	public startSessionOnApplicationBootstrap(): Promise<void> {
		return new Promise<void>(((resolve) => {

			// We don't care about the results.
			// Just start the session to see if user is logged in or not.
			this.startSession()
				.then(() => resolve())
				.catch(() => resolve());
		}));
	}

	get userData(): Observable<any> {
		return this._userData.asObservable().pipe(distinctUntilChanged());
	}

	get userDataValue(): any {
		return this._userData.getValue();
	}

	get isLoggedIn(): Observable<boolean> {
		return this._isLoggedIn.asObservable().pipe(distinctUntilChanged());
	}

	get isLoggedInValue(): boolean {
		return this._isLoggedIn.getValue();
	}

}
