import {VehicleType} from "../enum/Vehicle";
import {DatabaseEntity} from "../DatabaseEntity";
import {Domicile} from "../domicile/Domicile";

export class Fund extends DatabaseEntity {
	name: string;
	vehicleType: VehicleType;
	domicile: Domicile;
	children?: Array<Fund>;
	state?: string;
	isUmbrella?: boolean;

	public static transform(fund: Fund) {
		fund.state = fund.isUmbrella ? "close" : null;
		return fund;
	}
}
