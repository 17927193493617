import {Component, OnInit} from "@angular/core";
import {LandingMapService} from "../../services/landing-map.service";

interface Tooltip {
	id: string;
	name: string;
	info: Array<string>;
}

@Component({
	selector: "app-landing-map-tooltip",
	templateUrl: "./landing-map-tooltip.component.html",
	styleUrls: ["./landing-map-tooltip.component.scss"]
})
export class LandingMapTooltipComponent implements OnInit {
	public currentTooltip: any;

	public tooltips: Array<Tooltip> = [
		{
			"id": "GR",
			name: "Greece",
			info: [
				"Securities Transaction Tax",
				"0.2%",
				"Chargeable on Greek listed securities",
				"Liability of the seller"
			]
		},
		{
			"id": "PT",
			name: "Portugal",
			info: [
				"Financial Transaction Tax",
				"Rate tbc",
				"Confirmed as being held for the time being"
			]
		},
		{
			"id": "EG",
			name: "Egypt",
			info: [
				"Stamp Duty",
				"0.175%",
				"Charged on transfer and trading in securities, which included listed and unlisted shares on the Egyptian Stock Exchange",
				"Rate effective is 0.15% until 16 May 2020, then increases to 0.175%",
				"Purchases of shares exceeding 33% of the issuing company taxed at 0.3% on each the buyer and the seller."
			]
		},
		{
			"id": "PL",
			name: "Poland",
			info: [
				"Civil Law Activities Tax",
				"1%",
				"Charged on certain contracts",
				"Chargeable on Polish listed equities both on and off exchange; also on other property rights / goods, exemption for transactions on exchange",
				"Liability of the purchaser unless, inter alia, one of the parties is liable to / exempt from VAT due to a given transaction"
			]
		},
		{
			"id": "ZA",
			name: "South Africa",
			info: [
				"Securities Transaction Tax",
				"0.25%",
				"Charged on certain contracts",
				"Charged on SA and non SA issued but SA listed securities",
				"Liability of the broker (if used) or custodian (if no broker) or issuing company (if neither broker or custodian used e.g. unlisted)"
			]
		},
		{
			"id": "KR",
			name: "South Korea",
			info: [
				"Securities Transaction Tax",
				"0.25% for listed share transfers, 0.5% (0.45% on and after April 1, 2020) for unlisted share transfers",
				"Liability of the seller (buyer, if seller is a non-resident or foreign entity without a place of business in South Korea",
			]
		},
		{
			"id": "IN",
			name: "India",
			info: [
				"Securities Transaction Tax",
				"0.2%",
				"Joint split liability for both purchaser and seller",
				"Chargeable on equities and other products like derivatives and mutual funds listed in India",
				"For foreign portfolio investors e.g. non Indian fund products, off exchange transfers are not permitted other than if between manager of manager strategies",
			]
		},
		{
			"id": "GB",
			name: "United Kingdom",
			info: [
				"Stamp Duty and Stamp Duty Reserve Tax (“SDRT”)",
				" 0.5% (higher rate of 1.5% for transfers into depository systems and clearance services)",
				"Liability of the purchaser; collection by exchange member or qualified dealer e.g. broker or custodian through CREST or manually if not CREST settled",
				"Chargeable on UK (registered) equities and certain debt",
				"Can apply to non UK share transfers in certain instances",
				"Current dual regime framework under review by HMRC which is expected to extend the SDRT regime",
			]
		},
		{
			"id": "FR",
			name: "France",
			info: [
				"Financial Transaction Tax",
				"0.3%",
				"Liability of broker or custodian and recharged to investor",
				"Chargeable on French listed equities with capitalization >€1bn",
				"Exemptions for certain employee share schemes"
			]
		},
		{
			"id": "HK",
			name: "Hong Kong",
			info: [
				"Stamp Duty",
				"0.26%",
				"Joint split liability for both purchaser and seller",
				"Chargeable on any HK listed equities",
				"Non HK issued but HK listed e.g. Bermudan, Cayman Island, China H shares and dual listed shares e.g. Prada chargeable to HK stamp duty"
			]
		},
		{
			"id": "IE",
			name: "Ireland",
			info: [
				"Stamp Duty",
				// tslint:disable-next-line:max-line-length
				"Rate is 1% for main Irish equities potentially 7.5% and up to 10% if the shares derive their value from more than 10 residential properties.",
				"Liability of the purchaser",
				"Chargeable on Irish equities",
				"Collection in Euroclear if electronically settled, or manually in any other case."
			]
		},
		{
			"id": "IT",
			name: "Italy",
			info: [
				"Financial Transaction Tax",
				"0.2% for OTC trades, 0.1% for on exchange trades",
				"Liability of the purchaser and recharged to the investor",
				"Chargeable on Italian equities with capitalization >= €500m",
				"Brexit implications now limit the availability of the pension scheme, fund merger and reconstruction exemptions"
			]
		},
		{
			"id": "CH",
			name: "Switzerland",
			info: [
				"Residency and issuance tax basis",
				"Rate of 0.15% for Swiss issued shares",
				"Rate of 0.30% for non Swiss issued shares",
				"Various exemptions for certain institutional investors",
				"Exemptions apply for certain reorganisations",
				"Swiss broker is accountable for collecting and paying the tax"
			]
		},
		{
			"id": "SG",
			name: "Singapore",
			info: [
				"Stamp Duty",
				"0.2%",
				"Liability of the purchaser",
				"Chargeable on Singaporean listed equities",
				"Transfers of scripless shares within the Singapore central securities depository are not chargeable"
			]
		},
		{
			"id": "ID",
			name: "Indonesia",
			info: [
				"Final Income Tax charged on changes in ownership of listed shares on an exchange.",
				"If unlisted transfers are made, treaty rules apply",
				"0.1%",
				"Liability of the seller",
				"Chargeable on Indonesian listed equities"
			]
		},
		{
			"id": "TW",
			name: "Tawian",
			info: [
				"Securities Transaction Tax",
				"0.3%",
				"Liability of the seller",
				"Chargeable on Taiwanese listed and unlisted equities",
			]
		},
		{
			"id": "TH",
			name: "Thailand",
			info: [
				"Stamp Duty",
				"0.1%",
				"Liability of the seller unless contractually agreed otherwise.",
				"Chargeable on Thailand transfer unlisted equities and listed equities where the Thailand Securities Depositor Co. Ltd is not the registrar",
			]
		},
		{
			"id": "BE",
			name: "Belgium",
			info: [
				"Financial Transaction Tax",
				"0.12%/0.35%/1.32%",
				"Liability of the financial intermediary in the trade",
				"Applies to transactions that are concluded or executed outside Belgium, even through a non Belgian financial intermediary, where the purchaser is Belgian",
			]
		},
		{
			"id": "FI",
			name: "Finland",
			info: [
				"Transfer Tax",
				"1.6% unless Finnish securities are issued by a real estate rich company in which case 2%",
				"Liability of the purchaser, but if the purchaser is non-resident, the Finnish resident seller is liable to collect the transfer tax from the purchase and remit the tax",
				"No transfer tax on the transfer of publicly traded shares, or for transfers between non-resident parties – subject to exemptions",
			]
		},
		{
			"id": "AU",
			name: "Australia",
			info: [
				"Stamp Duty",
				"Various rates depending on state rules",
				"Chargeable on Australia unlisted equities i.e. listed equities are not chargeable",
				"Special rules for listed equities issued by real estate rich companies",
			]
		},
		{
			"id": "CN",
			name: "China",
			info: [
				"Stamp Duty",
				"0.1% on A Shares",
				"Liability of the seller",
				"Chargeable on Chinese listed equities",
				"Not chargeable on China H Shares listed on the HKSE – but see HK stamp duty rules",
			]
		},
		{
			"id": "ES",
			name: "Spain",
			info: [
				"Financial Transaction Tax",
				"0.2%",
				"Liability of broker or custodian and recharged to investor",
				"Chargeable on Spanish listed equities with capitalisation >€1bn",
				" Exemptions for fund mergers, spinoffs and reconstructions"
			]
		},
	];

	constructor(private mapService: LandingMapService) {}

	ngOnInit() {
		this.mapService.selectedJurisdiction.subscribe((code: string) => {
			const filterElement = this.tooltips.filter((e) => e.id === code)[0];

			if (filterElement) {
				this.currentTooltip = filterElement;
			} else {
				this.currentTooltip = null;
			}
		});
	}
}
