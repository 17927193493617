import {AfterViewInit, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ModalService} from "../../services/modal.service";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {Domicile} from "../../../model/data/domicile/Domicile";

// Remove branding
am4core.options.commercialLicense = true;

@Component({
	selector: "app-summary-chart",
	templateUrl: "./summary-chart.component.html",
	styleUrls: ["./summary-chart.component.scss"]
})
export class SummaryChartComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() data: any;
	@Input() id: string;
	@Input() auditLogId: string;
	@Input() jurisdiction: Domicile;

	public chart;

	constructor(public modal: ModalService) {
	}

	ngOnInit() {

	}

	ngAfterViewInit(): void {
		this.chart = am4core.create(this.id, am4charts.XYChart);

		// add data to chart
		this.chart.data = this.data;

		// format big numbers to readable
		this.chart.numberFormatter.numberFormat = "#a";
		this.chart.numberFormatter.bigNumberPrefixes = [
			{ "number": 1e+3, "suffix": "K" },
			{ "number": 1e+6, "suffix": "M" },
			{ "number": 1e+9, "suffix": "B" }
		];

		// set font size
		this.chart.fontSize = 12;

		// set up x and y axis
		const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "name";
		categoryAxis.renderer.labels.template.wrap = true;
		const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.min = 0;

		// create series
		const series = this.chart.series.push(new am4charts.ColumnSeries());

		// set series data fields
		series.dataFields.valueY = "value";
		series.dataFields.categoryX = "name";

		// create custom adapter for fill colors
		series.columns.template.adapter.add("fill", (fill, target)  => {
			if (target.dataItem.categoryX === "Actual") {
				return am4core.color("#005587");
			} else if (target.dataItem.categoryX === "Best Practice") {
				return am4core.color("#81bc00");
			} else {
				am4core.color("#00aaaa");
			}
		});
	}

	onClick() {
		this.modal.openSummaryDetailsModal(this.jurisdiction);
	}

	ngOnDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	}

}
