import {Observable} from "rxjs";
import { PendingPortfolio } from "../portfolio/PendingPortfolio";

import {AbstractApiConnector} from "./AbstractApiConnector";
import { FinishedPortfolio, PortfolioSecurities } from "../portfolio/PortfolioSecurities";
import {CalculationRequest} from "../../request/portfolio/CalculationRequest";
import {Calculation} from "../portfolio/Calculation";
import {CreatePortfolioRequest} from "../../request/portfolio/CreatePortfolioRequest";
import {Portfolio} from "../portfolio/Portfolio";

export class PortfolioApiConnector extends AbstractApiConnector {

	protected readonly apiRoute: string = "/api/v1/portfolio";

	public getTemplate(): Observable<Blob> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/template.xlsx`, null, {responseType: "blob"});
	}

	public createPortfolio(payload: CreatePortfolioRequest): Observable<Portfolio> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}`, payload);
	}

	public sendPortfolio(formData: FormData, id: string): Observable<PendingPortfolio> {
		return this.apiClient.authorizedOnly().post(`${this.apiRoute}/${id}`, formData);
	}

	public getPortfolioResults(id: string): Observable<FinishedPortfolio> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${id}`);
	}

	public getCalculations(query: CalculationRequest, id: string): Observable<Calculation> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}/${id}/calculate`, query);
	}

}
