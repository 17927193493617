import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {TaxRate} from "../tax-rate/TaxRate";

export class TaxRateApiConnector extends AbstractApiConnector {
	protected readonly apiRoute: string = "/api/v1/taxrate";

	public getTaxRates(taxRateIdList: string): Observable<Array<TaxRate>> {
		return this.apiClient.authorizedOnly().get(`${this.apiRoute}`, {id: taxRateIdList});
	}

	public updateTaxRates(id: string, taxRate: TaxRate): Observable<TaxRate> {
		return this.apiClient.authorizedOnly().put(`${this.apiRoute}/${id}`, taxRate);
	}
}
