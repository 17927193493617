import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AdminRoute} from "./model/data/route-definitions/AdminRoute";
import {StepEngineRoute} from "./model/data/route-definitions/StepEngineRoute";
import {CustomPreloader} from "./custom-preloader";


const routes: Routes = [
	{
		path: AdminRoute.ADMIN,
		loadChildren: "./admin/admin.module#AdminModule"
	},
	{
		path: StepEngineRoute.STEP_ENGINE,
		loadChildren: "./step-engine/step-engine.module#StepEngineModule",
		data: {preload: true}
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		preloadingStrategy: CustomPreloader,
		onSameUrlNavigation: "reload"
	})],
	providers: [CustomPreloader],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule {
}
