import {Pipe, PipeTransform} from "@angular/core";
import {Vehicle, VehicleType} from "../../model/data/enum/Vehicle";

@Pipe({
	name: "vehicleTypeTooltip"
})
export class VehicleTypeTooltipPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		switch (value) {
			case VehicleType.OEIC:
				return Vehicle.OEIC.popup;
			case VehicleType.ACS:
				return Vehicle.ACS.popup;
			case VehicleType.EU_CF:
				return Vehicle.EU_CF.popup;
			case VehicleType.LIFE_CO:
				return Vehicle.LIFE_CO.popup;
			default:
				return false;
		}
	}

}
