import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {RouterModule, Routes} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import { ErrorPageComponent } from "./pages/error-page/error-page.component";

const routes: Routes = [
	{
		path: "error",
		component: ErrorPageComponent
	}
];

@NgModule({
	declarations: [ErrorPageComponent],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule.forRoot(routes),
		TranslateModule.forChild(),
	]
})
export class UserModule {
}
