import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {LandingMapService} from "../../services/landing-map.service";

am4core.useTheme(am4themes_animated);

// Remove branding
am4core.options.commercialLicense = true;

@Component({
	selector: "app-landing-map",
	templateUrl: "./landing-map.component.html",
	styleUrls: ["./landing-map.component.scss"]
})
export class LandingMapComponent implements OnInit, OnDestroy, AfterViewInit {
	private chart;

	private availableJurisdictions: Array<string> = [
		"GR", "PT", "EG", "PL", "ZA", "KR", "IN",
		"GB", "FR", "HK", "IE", "IT", "CH", "SG",
		"ID", "TW", "TH", "BE", "FI", "AU", "CN",
		"ES"];

	private highlightedCountries: Array<any> = [];

	private markedCountries: Array<any> = [{
		"latitude": 22.286394,
		"longitude": 114.149139,
		"id": "HK"
	}];

	constructor(private mapService: LandingMapService) {}

	ngOnInit() {
		this.availableJurisdictions.forEach((countryCode) => {
			this.highlightedCountries.push({
				"id": countryCode,
				"fill": am4core.color("#084A7B")
			});
		});
	}

	ngAfterViewInit(): void {
		this.chart = am4core.create("mapContainer", am4maps.MapChart);
		// Disable zoom, drag and resize
		this.chart.seriesContainer.draggable = false;
		this.chart.seriesContainer.resizable = false;
		this.chart.maxZoomLevel = 1;

		// Set map definition
		this.chart.geodata = am4geodata_worldLow;

		// Set projection
		this.chart.projection = new am4maps.projections.Mercator();

		// Create map polygon series
		const polygonSeries = this.chart.series.push(new am4maps.MapPolygonSeries());

		// Exclude Antarctica
		polygonSeries.exclude = ["AQ"];

		// Make map load polygon (like country names) data from GeoJSON
		polygonSeries.useGeodata = true;

		// set polygon outlines
		polygonSeries.mapPolygons.template.strokeWidth = 1;
		polygonSeries.mapPolygons.template.stroke = am4core.color("#1E2629");

		// Configure series
		const polygonTemplate = polygonSeries.mapPolygons.template;
		polygonTemplate.fill = am4core.color("#2A3940");

		// set countries to select and bind fill property
		polygonSeries.data = this.highlightedCountries;
		polygonTemplate.propertyFields.fill = "fill";

		// create highlight effect for hover
		const hover = polygonTemplate.states.create("highlight");
		hover.properties.fill = am4core.color("#81bc00");

		// set hover state function
		polygonTemplate.events.on("over", (event) => {
			if (this.availableJurisdictions.filter(j => j === event.target.dataItem.dataContext.id).length > 0) {
				event.target.setState("highlight");
				event.target.cursorOverStyle = am4core.MouseCursorStyle.pointer;
				this.mapService.setSelectedJurisdiction(event.target.dataItem.dataContext.id);
			}
		});

		// set out state function
		polygonTemplate.events.on("out", (event) => {
			event.target.setState("default");
			this.mapService.setSelectedJurisdiction(null);
		});

		// add circle for small countries
		const imageSeries = this.chart.series.push(new am4maps.MapImageSeries());
		const imageSeriesTemplate = imageSeries.mapImages.template;
		const circle = imageSeriesTemplate.createChild(am4core.Circle);

		// set radius, stroke and mouseover effect
		circle.radius = 6;
		circle.fill = am4core.color("#084A7B");
		circle.nonScaling = true;
		circle.stroke = am4core.color("#1E2629");
		circle.strokeWidth = 1;
		circle.cursorOverStyle = am4core.MouseCursorStyle.pointer;

		// create hover effect
		const originHoverState = circle.states.create("hover");
		originHoverState.properties.fill = am4core.color("#80ba00");

		// add data and bind properties
		imageSeries.data = this.markedCountries;
		imageSeriesTemplate.propertyFields.latitude = "latitude";
		imageSeriesTemplate.propertyFields.longitude = "longitude";

		// set hover state function
		imageSeriesTemplate.events.on("over", (event) => {
			if (this.availableJurisdictions.filter(j => j === event.target.dataItem.dataContext.id).length > 0) {
				this.mapService.setSelectedJurisdiction(event.target.dataItem.dataContext.id);
			}
		});

		// set out state function
		imageSeriesTemplate.events.on("out", (event) => {
			this.mapService.setSelectedJurisdiction(null);
		});

	}


	ngOnDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
}
