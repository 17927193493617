import {AfterViewInit, Component, Input, OnInit} from "@angular/core";
import BigNumber from "bignumber.js";
import {Currency} from "../../../model/data/portfolio/Calculation";
import {Domicile} from "../../../model/data/domicile/Domicile";
import {TransactionType} from "../../../model/data/enum/TransactionType";

@Component({
	selector: "app-summary-table",
	templateUrl: "./summary-table.component.html",
	styleUrls: ["./summary-table.component.scss"]
})
export class SummaryTableComponent implements OnInit, AfterViewInit {

	@Input() actualTaxDue: string | BigNumber;
	@Input() currency: Currency;
	@Input() bestPracticeTaxDue: string | BigNumber;
	@Input() savings: string | BigNumber;
	@Input() isBreakdown: boolean;
	@Input() jurisdiction: Domicile;
	@Input() nextStep: string;
	@Input() transactionType?: TransactionType;

	public isAdviceVisible: boolean;
	public isMemoVisible: boolean;
	public isHKMergerRemarkVisible: boolean;

	constructor() {
	}

	ngOnInit() {
		this.isAdviceVisible = this.adviceVisibility(this.savings);
		this.isMemoVisible = true;
		// tslint:disable-next-line:max-line-length
		this.isHKMergerRemarkVisible = this.transactionType && this.transactionType === TransactionType.MERGER && this.jurisdiction && this.jurisdiction.code === Domicile.HK.code;

		this.bestPracticeTaxDue = this.transformNumber(this.bestPracticeTaxDue);
		this.actualTaxDue = this.transformNumber(this.actualTaxDue);
		this.savings = this.transformNumber(this.savings);
	}

	ngAfterViewInit(): void {
		if (this.nextStep) {
			this.nextStep = this.nextStep.replace(/\n/g, "<br>");
		}
	}

	public transformNumber(n: string | BigNumber): string {
		return typeof n !== "string" ? Math.round(n.toNumber()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
	}

	public adviceVisibility(n: string | BigNumber): boolean {
		if (typeof n !== "string") {
			return n.toNumber() > 0;
		}
	}

}
