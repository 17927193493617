import {BrowserModule} from "@angular/platform-browser";
import {APP_INITIALIZER, NgModule} from "@angular/core";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {FormsModule} from "@angular/forms";
// own
import {AppComponent} from "./app.component";
import {CommonsModule} from "./commons/commons.module";
import {ModelModule} from "./model/model.module";
import {OAuthApiClient} from "./model/data/utility/OAuthApiClient";
import {UserModule} from "./user/user.module";
import {AppRoutingModule} from "./app-routing.module";
import {UserSessionService} from "./user/services/user-session.service";
import {userSessionStarterFactory} from "./user/util/UserSessionStarterFactory";
import {ApiCommunicationService} from "./model/services/ApiCommunicationService";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {SuiModule} from "@richardlt/ng2-semantic-ui";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CookieService} from "ngx-cookie-service";
import {LandingModule} from "./landing/landing.module";
import {InlineSVGModule} from "ng-inline-svg";
import {ConfigProviderService} from "./commons/services/config-provider.service";

// http loader for translations file
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		CommonsModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		SuiModule,

		// own modules
		ModelModule,
		UserModule,
		LandingModule,

		// 3rd party modules
		// ngx-translate
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),

		InlineSVGModule.forRoot(),

		// main routing module
		AppRoutingModule,
	],
	providers: [
		// Communication service
		ApiCommunicationService,

		// Fetch configuration
		ConfigProviderService,

		// user session handler
		UserSessionService,

		// hook UserSessionService's session start action into app initialization hook
		{
			provide: APP_INITIALIZER,
			useFactory: userSessionStarterFactory,
			deps: [UserSessionService],
			multi: true
		},
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy
		},

		// OAuth API client
		OAuthApiClient,

		// Cookie Service
		CookieService,
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
