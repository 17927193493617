import {Inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {UserSessionService} from "../../user/services/user-session.service";
import {StepEngineRoute} from "../../model/data/route-definitions/StepEngineRoute";

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(@Inject(UserSessionService) private userSessionService: UserSessionService,
				private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
		// get value
		const isLoggedIn = this.userSessionService.isLoggedInValue;

		if (!isLoggedIn) {
			// fall back to error page
			this.router.navigate([StepEngineRoute.AUTH_ERROR]);
		}

		// decision based on logged in state
		return isLoggedIn;
	}

}
