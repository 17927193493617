import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LandingPageComponent} from "./pages/landing-page/landing-page.component";
import {AuthGuard} from "../commons/guards/auth.guard";
import {LandingRoute} from "../model/data/route-definitions/LandingRoute";

const routes: Routes = [
	{
		path: LandingRoute.LANDING,
		component: LandingPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: "always"
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LandingRoutingModule {
}
