import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

import { LandingPageComponent } from "./pages/landing-page/landing-page.component";
import {CommonsModule} from "../commons/commons.module";
import {AuthGuard} from "../commons/guards/auth.guard";
import {LandingRoutingModule} from "./landing-routing.module";
import { LandingMapComponent } from "./components/landing-map/landing-map.component";
import { LandingNavComponent } from "./components/landing-nav/landing-nav.component";
import { LandingMapTooltipComponent } from "./components/landing-map-tooltip/landing-map-tooltip.component";
import {LandingMapService} from "./services/landing-map.service";

@NgModule({
	declarations: [
		LandingPageComponent,
		LandingMapComponent,
		LandingNavComponent,
		LandingMapTooltipComponent
	],
	imports: [
		CommonModule,
		TranslateModule.forChild(),
		LandingRoutingModule
	],
	exports: [
		LandingPageComponent
	],
	providers: [
		// Services
		LandingMapService
	]
})
export class LandingModule { }
